import useBatchMtokenInfo, { DECIMAL_PLACES } from '@/store/useBatchMtoken';
import { usePointsStatus } from '@/store/usePointsStatus';
import { Popover } from 'antd';
import IBignumber from 'bignumber.js';
import classNames from 'classnames';
import { useEffect, useMemo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import metamaskIcon from '@/assets/imgs/metamask.png';
import arrowBlack from '@/assets/svgs/arrow-black.svg';
import infoIcon from '@/assets/svgs/info-gray.svg';
import config from '@/config';
import { useAddToken } from '@/hooks/useAddToken';
import { useEarnMoreStatus } from '@/store/useEarnMoreInfo';
import useGetMtokenStake from '@/store/useGetMTokenStake';
import useUsdPrices from '@/store/useUsdPrices';
import useUserInfo from '@/store/useUserInfo';
import { useUserMTokenBalance } from '@/store/useUserMTokenBalance';
import { fromWei, numbroSafe } from '@/utils/format';
import MtokenDetail from '../MtokenDetail';
import NewButton from '../NewButton';
import UserAgreement from '../UserAgreement';

const { MANTA_PACIFIC_CHAIN, mBTC, mETH, mUSD, mwBETH } = config;

IBignumber.config({ EXPONENTIAL_AT: 99 });

interface TokenConfig {
  address: string;
  decimals: number;
  name: string;
}

interface TokenDetail {
  amount: string;
  protocol: string;
}

type TokenKeys = 'mBTC' | 'mETH' | 'mUSD' | 'mwBETH';

interface TokenDisplay {
  hasProtocolBalance: boolean;
  details: TokenDetail[];
  token: TokenConfig;
  mtokenBalance: string;
  stake: string;
  totalAmount: string;
  symbol: TokenKeys;
  onAddToken: () => Promise<void>;
}

function Dot() {
  return (
    <span className="inline-block w-[10px] h-[10px] bg-green rounded-md"></span>
  );
}

export const MyPoint = () => {
  const { userInfo } = useUserInfo();
  const { user_address } = userInfo;
  const { pointStatus } = usePointsStatus();
  const pointsData = pointStatus?.data;
  const showData = user_address && pointsData && pointsData?.code !== -2;

  return (
    <div className="flex flex-col w-[260px] rounded-tr-2xl rounded-br-2xl bg-white/80  pl-12 pr-6 py-6 border border-[#05D9A6]/60 text-black-title text-[20px] font-[400] max-md:flex-col max-md:items-center max-md:mt-2 max-md:px-6 max-md:py-4 max-md:w-full max-md:text-[14px] max-md:rounded-lg ">
      <p>Fourth Round Points</p>
      <div className="flex items-start gap-2 relative md:hidden">
        <div className="gradient-text-1 text-[14px] font-[500] italic">
          Current APR: 1%
        </div>
        <Popover
          overlayClassName="point-popover"
          arrow={false}
          placement="bottom"
          content={() => (
            <div className="bg-[#1D314C99] text-white p-2 rounded-[4px]">
              Points will be exchangeable for Manta Tokens in the future. The
              earnings from Points will be proportional to the length of time
              M-Tokens are held.
            </div>
          )}
          trigger="hover"
        >
          <img src={infoIcon} width={17} height={17} alt={'infoIcon'} />
        </Popover>
      </div>
      <div className="flex mt-2 flex-col items-end max-md:text-[24px]">
        {showData ? pointStatus?.data?.data?.total_scores || '0' : '--'}
      </div>
      <div className="mt-1 text-sm text-black-title opacity-70">
        Points for the current round start accumulating immediately after the
        previous snapshot. You need to stake at least $100 or an equivalent
        amount in tokens to claim the reward points.
      </div>
    </div>
  );
};

const formatAmount = (amount: string): string => {
  return numbroSafe(amount).format({
    thousandSeparated: true,
    mantissa: DECIMAL_PLACES,
    trimMantissa: true
  });
};

const TokenDisplay = ({
  hasProtocolBalance,
  stake,
  details,
  token,
  mtokenBalance,
  symbol,
  totalAmount,
  onAddToken
}: TokenDisplay) => {
  const hasValue = new IBignumber(totalAmount).gt(0);
  const formattedAmount = hasValue ? formatAmount(totalAmount) : '0';

  const content = (
    <>
      {formattedAmount} {symbol}
    </>
  );

  const showPopover = hasProtocolBalance || new IBignumber(stake).gt(0);

  return (
    <p className="flex items-center">
      {showPopover ? (
        <Popover
          overlayClassName="point-popover"
          arrow={false}
          placement="right"
          content={() => (
            <MtokenDetail
              details={details}
              token={token}
              stake={stake}
              mtokenBalance={mtokenBalance}
            />
          )}
          trigger="hover"
        >
          <span className="underline">{content}</span>
        </Popover>
      ) : (
        content
      )}
      <img
        onClick={onAddToken}
        className="ml-2 w-5 h-5 hover:opacity-80 hover:cursor-pointer"
        src={metamaskIcon}
        alt="Add to MetaMask"
      />
    </p>
  );
};

export const MTokenInfo = () => {
  const navigate = useNavigate();
  const {
    userInfo: { user_address }
  } = useUserInfo();
  const { data, mutate: mutateBatchMtokenInfo } = useBatchMtokenInfo();
  const { data: mData, mutate: mutateUserMTokenBalance } =
    useUserMTokenBalance();
  const { data: mtokenStateData } = useGetMtokenStake();
  const { addTokenToWalletWithCertainChain } = useAddToken();
  const usdPrices = useUsdPrices();

  const [mbtcBalance, methBalance, musdBalance, mwbethBalance] = data || [];

  const safeFromWei = (value: any): string => {
    if (!value || value === '0') return '0';
    try {
      return fromWei(value?.toString()).toString();
    } catch {
      return '0';
    }
  };

  const calculateProtocolAmount = (details: TokenDetail[] = []) =>
    details
      .filter(d => d.protocol !== 'balance')
      .reduce((sum, d) => sum.plus(d.amount || '0'), new IBignumber(0));

  const TOKEN_MAP = {
    mBTC: { token: mBTC, balance: mbtcBalance },
    mETH: { token: mETH, balance: methBalance },
    mUSD: { token: mUSD, balance: musdBalance },
    mwBETH: { token: mwBETH, balance: mwbethBalance }
  } as const;

  const processToken = (key: TokenKeys): TokenDisplay | null => {
    const config = TOKEN_MAP[key];
    if (!config?.token) return null;

    const tokenData = mData?.data?.[key];
    const details = tokenData?.details || [];
    const stakeIndex = Object.keys(TOKEN_MAP).indexOf(key);
    const amount = safeFromWei(config.balance?.balanceOfOriginData || '0');
    const stakeAmount = safeFromWei(
      mtokenStateData?.[stakeIndex]?.stakedAmount || '0'
    );
    const hasProtocolAmount = details
      .filter(d => d.protocol !== 'balance')
      .reduce((acc, d) => acc.plus(d.amount || '0'), new IBignumber(0));
    const totalAmount = new IBignumber(amount)
      .plus(stakeAmount)
      .plus(hasProtocolAmount)
      ?.toString();

    return {
      mtokenBalance: amount,
      stake: stakeAmount,
      token: config.token,
      hasProtocolBalance: new IBignumber(hasProtocolAmount).gt(0),
      details,
      totalAmount,
      symbol: key,
      onAddToken: () =>
        addTokenToWalletWithCertainChain(MANTA_PACIFIC_CHAIN.id, {
          address: config.token.address,
          decimals: config.token.decimals,
          symbol: config.token.name
        })
    };
  };

  const tokenList = useMemo(
    () =>
      Object.keys(usdPrices)
        .map(key => processToken(key as TokenKeys))
        .filter((token): token is TokenDisplay => token !== null),
    [usdPrices, data, mData, mtokenStateData]
  );

  const totalValue = useMemo(() => {
    if (!usdPrices || !data || !mtokenStateData) return '0';

    try {
      return formatAmount(
        tokenList
          .reduce((acc, token) => {
            const price = usdPrices[token.symbol];
            return price
              ? acc.plus(new IBignumber(token.totalAmount).times(price))
              : acc;
          }, new IBignumber(0))
          .toString()
      );
    } catch (error) {
      console.error('Error calculating total value:', error);
      return '0';
    }
  }, [usdPrices, tokenList]);

  useEffect(() => {
    if (user_address) {
      mutateBatchMtokenInfo();
      mutateUserMTokenBalance();
    }
  }, [user_address]);

  return (
    <div className="flex flex-col w-[260px] rounded-tr-2xl rounded-br-2xl bg-white/80 pr-6 py-6 border border-[#05D9A6]/60">
      <div className="flex gap-2 items-center pl-12 max-md:pl-0">
        <p className="max-md:text-[20px]">Inventory</p>{' '}
        <Popover
          overlayClassName="point-popover"
          arrow={false}
          placement="bottom"
          content={() => (
            <div className="p-2">
              Assets deposited from the ETH/BSC arrives in about 30 minutes. The
              rest normally arrives within minutes.
            </div>
          )}
          trigger="hover"
        >
          <img src={infoIcon} width={17} height={17} alt={'infoIcon'} />
        </Popover>
      </div>
      <div className="text-xs mt-1 text-default-black/70 mb-2 pl-12 max-md:pl-0">
        Receive mTokens by depositing your crypto
      </div>

      <div className="flex flex-col items-end gap-2 text-[16px] max-md:items-center">
        <p className="text-right">
          Total Value: ≈ {totalValue ? `$ ${totalValue}` : '--'}
        </p>
        {tokenList.map(token => (
          <TokenDisplay key={token.symbol} {...token} />
        ))}
        <span className="text-end text-xs text-black-title/70 font-normal ">
          Hourly Update
        </span>
        <div className="p-2 flex flex-col gap-1  text-black-title/70 text-[14px] font-[400] whitespace-nowrap max-md:w-full border-t border-b border-dashed border-green">
          <div className="flex flex-col items-end gap-1 max-md:items-center">
            <p>1 MBTC = {mbtcBalance?.rewardMultiplier} BTC</p>
            <p>1 METH = {methBalance?.rewardMultiplier} ETH</p>
            <p>1 MUSD = {musdBalance?.rewardMultiplier} USDT/USDC</p>
            <p>1 MwBETH = {mwbethBalance?.rewardMultiplier} wBETH</p>
          </div>
          <p className="text-[12px] font-[400] text-black-title/70 text-right italic max-md:text-center">
            Updated every day at 5:00 AM UTC
          </p>
        </div>
        <div
          onClick={() => {
            navigate('/dashboard/history');
          }}
          className="flex text-[14px] items-center gap-2 text-black-title hover:opacity-80 hover:cursor-pointer"
        >
          <span>Deposit History</span>
          <img src={arrowBlack} />
        </div>
      </div>
    </div>
  );
};

const SubNav = () => {
  const { pathname } = useLocation();
  const { data: earnMoreData } = useEarnMoreStatus();
  const earnMorelist = earnMoreData?.data?.project_list || [];
  const hasNew = earnMorelist.filter(e => e?.is_new).length > 0;

  const isStakeSelected =
    pathname === '/dashboard' || pathname === '/dashboard/stake';
  const isPointsSelected = pathname === '/dashboard/points';
  const isEarnMoreSelected = pathname === '/dashboard/earn_more';
  const isStakeMTokenSelected = pathname === '/dashboard/m_token';
  return (
    <div className=" flex flex-col gap-4 ">
      <div className="flex flex-col gap-4 w-[260px] rounded-tr-2xl rounded-br-2xl bg-white/80 pl-12 pr-[18px] py-6 border border-[#05D9A6]/60">
        <Link
          className={classNames(
            'h-[29px] leading-6 text-xl flex items-center gap-2 font-[300]',
            {
              'text-green': isStakeSelected
            }
          )}
          to="stake"
        >
          {isStakeSelected && <Dot />}Deposit
        </Link>
        <Link
          className={classNames(
            'h-[29px] leading-6 text-xl flex items-center gap-2 font-[300]',
            {
              'text-green': isStakeMTokenSelected
            }
          )}
          to="m_token"
        >
          {isStakeMTokenSelected && <Dot />}Stake M-Tokens
        </Link>
        <Link
          className={classNames(
            'h-[29px] leading-6 text-xl flex items-center gap-2 font-[300]',
            {
              'text-green': isPointsSelected
            }
          )}
          to="points"
        >
          {isPointsSelected && <Dot />}Points
        </Link>
        <Link
          className={classNames(
            'h-[29px] text-nowrap leading-1 text-xl flex items-center gap-2 font-[300]',
            {
              'text-green': isEarnMoreSelected
            }
          )}
          to="earn_more"
        >
          {isEarnMoreSelected && <Dot />}
          Earn More
          {hasNew && <NewButton />}
        </Link>
      </div>
      <MTokenInfo />
      <MyPoint />
      <UserAgreement />
    </div>
  );
};

export default SubNav;
